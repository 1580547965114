@font-face {
  font-family: PPBold;
  src: url("../fonts/PPNeueMachina-Ultrabold.woff2");
}
@font-face {
  font-family: PPRegular;
  src: url("../fonts/PPNeueMachina-Regular.woff2");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: PPRegular;
  color: #151a1c;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fcfeff;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fcfeff;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: white;
}

.hero {
  padding: 120px 0;
  background-color: #ffcf0a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 120px;
}
.hero .inner-hero {
  text-align: center;
}
.hero .inner-hero .hero-header {
  font-family: PPBold;
  font-size: 70px;
  margin-bottom: 25px;
}
.hero .inner-hero .hero-sub-header {
  font-size: 20px;
}
.hero .inner-hero .hero-sub-header p {
  margin-top: 10px;
}
.hero .inner-hero a {
  display: inline-block;
  text-decoration: none;
  color: #ffcf0a;
  background-color: #151a1c;
  padding: 12px 40px;
  margin-top: 40px;
  border-radius: 5px;
  border: 2px solid #151a1c;
}
.hero .inner-hero a:hover {
  background-color: #ffcf0a;
  color: #151a1c;
}

.offer {
  margin: 150px auto 150px auto;
  text-align: center;
}
.offer .offer-head {
  font-size: 30px;
  font-family: PPBold;
  margin: 50px 0;
}
.offer .resoources-we-offer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 70%;
  margin: auto;
  grid-gap: 1rem;
}
.offer .resoources-we-offer p {
  border: 1px solid #151a1c;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}

.everybody {
  margin: 0 auto 150px auto;
  width: 70%;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5rem;
}
.everybody .everybody-description {
  padding: 30px;
  text-align: justify;
}
.everybody .everybody-description .head-small {
  background: linear-gradient(rgba(255, 207, 10, 0.3), rgba(255, 207, 10, 0.3));
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}
.everybody .everybody-description .head-big {
  font-family: PPBold;
  font-size: 30px;
  margin: 30px 0;
}

.testimonies {
  background-color: #151a1c;
  padding: 50px 0 100px 0;
}
.testimonies .testimonies-head {
  color: #fcfeff;
  text-align: center;
  font-size: 30px;
  font-family: PPBold;
  padding: 50px 0;
}
.testimonies .testimonies-inner {
  margin: auto;
  width: 70%;
}
.testimonies .testimonies-inner .the-testimonies {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}
.testimonies .testimonies-inner .the-testimonies > div {
  border: 1px solid #ffcf0a;
  border-radius: 10px;
  padding: 30px;
}
.testimonies .testimonies-inner .the-testimonies > div .testimony-body {
  color: #c9cecf;
  margin-bottom: 70px;
}
.testimonies .testimonies-inner .the-testimonies > div .person-name {
  color: #fcfeff;
}
.testimonies .testimonies-inner .the-testimonies > div .person-position {
  color: #c9cecf;
}
.testimonies .testimonies-inner .top-quote {
  margin-bottom: 20px;
}
.testimonies .testimonies-inner .bottom-quote {
  text-align: right;
  margin-top: 20px;
}
.testimonies .testimonies-inner img {
  width: 2%;
}

.free {
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4rem;
  padding: 150px 0;
}
.free > div {
  border-radius: 20px;
}
.free > div p {
  font-family: PPBold;
  font-size: 40px;
  padding: 30px;
}
.free .up {
  background-color: #ffcf0a;
}
.free .it-is-free {
  background-color: #151a1c;
}
.free .it-is-free p {
  color: #fcfeff;
}

.wait {
  background-color: #ffcf0a;
}
.wait .wait-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}
.wait .wait-inner .wait-about p {
  font-family: PPBold;
  font-size: 40px;
}
.wait .wait-inner .wait-about a {
  display: inline-block;
  text-decoration: none;
  color: #ffcf0a;
  background-color: #151a1c;
  padding: 12px 40px;
  margin-top: 40px;
  border-radius: 5px;
  border: 2px solid #151a1c;
}
.wait .wait-inner .wait-about a:hover {
  background-color: #ffcf0a;
  color: #151a1c;
}
.wait img {
  width: 50%;
}

.footer .footer-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .footer-inner div {
  margin-top: -100px;
  background-color: #ffcf0a;
  padding: 40px;
  border-radius: 20px;
}
.footer .footer-inner div p {
  font-family: PPBold;
  font-size: 40px;
  margin-bottom: 10px;
}
.footer .footer-inner img {
  width: 40%;
}
.footer .abrusu {
  background-color: #151a1c;
  color: #fcfeff;
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  font-family: PPBold;
}

.soon {
  background-color: #151a1c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}
.soon .the-form .cta {
  margin: 20px 0 30px 0;
}
.soon .the-form form input {
  width: 50%;
  padding: 20px;
  border: none;
  background-color: black;
  color: white;
}
.soon .the-form form button {
  padding: 20px;
  border: none;
  background-color: #ffcf0a;
}
.soon .the-form form button:hover {
  cursor: pointer;
}
.soon p {
  color: white;
  font-size: 40px;
  font-family: PPBold;
}

.resourcelinks .resourcelinks-hero {
  background-color: #ffcf0a;
  border-bottom-left-radius: 120px;
  padding: 200px 0;
}
.resourcelinks .resourcelinks-hero p {
  font-family: PPBold;
  font-size: 70px;
  text-align: center;
}
.resourcelinks .all-resouces {
  width: 70%;
  margin: 150px auto 100px auto;
}
.resourcelinks .all-resouces > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  background: linear-gradient(rgba(255, 207, 10, 0.3), rgba(255, 207, 10, 0.3));
  margin-bottom: 50px;
  align-items: center;
}
.resourcelinks .all-resouces > div div {
  padding: 30px;
  text-align: center;
}
.resourcelinks .all-resouces > div div a {
  display: inline-block;
  text-decoration: none;
  color: #ffcf0a;
  background-color: #151a1c;
  padding: 12px 40px;
  margin-top: 40px;
  border-radius: 5px;
  border: 2px solid #151a1c;
}
.resourcelinks .all-resouces > div div a:hover {
  background: linear-gradient(rgba(255, 207, 10, 0), rgba(255, 207, 10, 0));
  color: #151a1c;
}
.resourcelinks img {
  width: 100%;
}